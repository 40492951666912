.appt-book {
  .appt-book-location-selector {
    .city-select,
    .location-select {
      @include swap_direction(padding, 0 35px 0 9px);
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  width: 100%;
  &.photo-tip {
    padding-top: 5px;
  }
  a {
    text-decoration: none;
  }
  .tooltiptext-under,
  .tooltiptext-over {
    visibility: hidden;
    width: 100%;
    max-width: 400px;
    border: 1px solid $color--border;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .tooltiptext-under {
    top: 125%;
  }
  .tooltiptext-over {
    bottom: 125%;
  }
  .tooltiptext-under::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
  }
  .tooltiptext-over::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
  }
  &:hover .tooltiptext-under,
  &:hover .tooltiptext-over {
    visibility: visible;
    opacity: 1;
  }
}

input:invalid {
  box-shadow: none;
}

#booking-step3 {
  .book-appt-container {
    .appt-book-register-password-text {
      .password-notice {
        display: none;
      }
    }
  }
}
