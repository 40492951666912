.tabbed-products-block {
  &__tab {
    width: 25%;
  }
}
.appt-book {
  &.services {
    .book-now-bar {
      &.top {
        .button {
          .price {
            float: $ldirection;
            margin: 0;
          }
        }
      }
    }
  }
}
