@include breakpoint($bp--small-up) {
  [dir='rtl'] {
    .artistry-landing-artists {
      .artistry--mosaic-tile:nth-of-type(2) {
        margin-left: -100% !important;
        margin-right: 37.5% !important;
      }
      .artistry--mosaic-tile:nth-of-type(4) {
        margin-left: -100% !important;
        margin-right: 0 !important;
      }
    }
    .artistry--mosaic-tile:nth-of-type(2n + 2) {
      margin-right: -100% !important;
      margin-left: 0 !important;
    }
    .artistry-videos-formatter-v1 {
      .artistry-video-section {
        &__grid {
          .grid--item:nth-of-type(1n) {
            margin-left: 0px;
          }
        }
      }
    }
  }
}

.site-container {
  .artistry-video-player-wrapper {
    .prod_inv_status-2 {
      .product__footer {
        bottom: 50px;
        a.product__add-to-faves {
          top: 0px;
        }
        .product__price {
          margin: 0px;
        }
        .temp-out-of-stock {
          .temp-outof-stock_text {
            top: 70px;
          }
        }
      }
    }
  }
}

[dir='rtl'] {
  .site-container {
    .layout--artistry-artist {
      .product--teaser {
        .product__footer {
          .product__price {
            float: #{$ldirection};
          }
        }
      }
    }
  }
}

.artist-video-carousel-v1.artist--videos {
  .carousel {
    .media-block--video {
      .media-block__caption--two-col > footer {
        float: #{$rdirection};
      }
      .media-block__body {
        display: block;
      }
      header {
        .media-block--video-duration {
          display: none;
        }
      }
      footer {
        .media-block--video-duration {
          display: block;
        }
      }
    }
  }
}
