@if $password_strengthen {
  .password-field {
    &__checkout {
      @include breakpoint($bp--medium-up) {
        bottom: 20%;
      }
    }
  }
  .appt-book-section {
    .password-field {
      position: unset;
      &__info {
        @include breakpoint($bp--medium-up) {
          #{$ldirection}: 55%;
          bottom: 30%;
        }
      }
    }
  }
  .change-password {
    #cboxClose {
      color: $color--black;
    }
  }
  .ar_AE {
    .password-field {
      &__info {
        &:before {
          transform: rotate(180deg);
          -o-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
        }
      }
    }
  }
}
